import React, { useState } from "react"
//import Image from "./Image";
//import TestimonialCard from "../components/testimonial-card"
//import useTestimonialsBlock from "../hooks/useTestimonialsBlock"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const Opportunities = ({ items, className }) => {

  //const items = useTestimonialsBlock()
  //const testimonials = items.filter(e => e.label)

  //if (testimonials.length === 0) return false

  /* return (
    <div className="flex flex-col lg:flex-row  border">
      {items.map((item, index) => (
        <Section4Card key={index} {...item} />

      ))}
    </div>
  )
 */
  return (
    <>
      <div className="hidden lg:block">
        <Swiper
          modules={[Navigation, /*Pagination */ Keyboard, A11y]}
          className={className}
          spaceBetween={0}
          slidesPerView={4}
          centeredSlides={true}
          roundLengths={true}
          initialSlide={2}
          loop={true}
          loopAdditionalSlides={30}
          //navigation={isMobile ? false : true}
          navigation
          keyboard={true}  
          wrapperTag={'ul'}    
        //pagination={{ clickable: true }}
        >
          {items.map((item, i) => (
            <SwiperSlide className="mb-12"  tag="li" key={i} tabIndex={i}>
              <OpportunitiesCardDesktop  {...item} index={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className=" lg:hidden px-4">
        <Swiper
          modules={[Pagination]}
          className={className}
          spaceBetween={0}
          slidesPerView={1}
          //initialSlide={2}
          //navigation={isMobile ? false : true}
          //navigation
          pagination={{ clickable: true }}
        >
          {items.map((item, i) => (
            <SwiperSlide className="mb-12" key={i}>
              <OpportunitiesCardMobile  {...item}  />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}


const OpportunitiesCardDesktop = ({ title, description, image, relationships, index, ...props }) => {


  const [show, setShow] = useState(false)

  const grey = 'rgba(66,66,66, 0.36),      rgba(66,66,66, 0.36)'
  const blue = 'rgba(6,92,167, 0.85),      rgba(6,92,167, 0.85)'
  return (
    <div
      className="  p-8 lg:h-[532px]  lg:w-[480px] lg:text-white space-y-8 	"
      style={{ backgroundImage: `linear-gradient( ${show ? blue : grey}) , url('${relationships.images[0].localFile.publicURL}') ` }}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      //tabIndex={index}
    >
      <h3 className="text-center text-2xl font-bold shadow-xl text-white">{title}</h3>
      <div className="relative h-[250px]  overflow-hidden " >
        <div className={" text-center text-lg transition-all duration-500	 " + (show ? 'block' : 'hidden')} dangerouslySetInnerHTML={{ __html: description.processed }} />
      </div>
    </div >
  )
}

const OpportunitiesCardMobile = ({ title, description, images, relationships, ...props }) => {
  /* console.log(relationships) */

  const grey = 'rgba(66,66,66, 0.36),      rgba(66,66,66, 0.36)'
  //const blue = 'rgba(6,92,167, 0.85),      rgba(6,92,167, 0.85)'
  return (
    <div
      className="space-y-4 h-[400px] p-8 text-white"
      style={{ backgroundImage: `linear-gradient( ${grey}) , url('${relationships.images[0].localFile.publicURL}') ` }}
    >
      <h3 className="text-center text-[22px] font-bold shadow-xl text-white">{title}</h3>
      <div className={" max-h-[300px] overflow-hidden text-right text-lg"} dangerouslySetInnerHTML={{ __html: description.processed }} />
    </div >
  )

}

export default Opportunities
